import {
  closestCenter,
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { DragEndEvent } from '@dnd-kit/core/dist/types';
import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { TestResponsePrivate } from '@tests/types';
import React, { SetStateAction, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { QuestionPreview, SortableItem } from '@/components';
import { questionsItemsSelector } from '@/selectors/questions';
import { useGetQuestionsListQuery, useUpdateQuestionsOrderMutation } from '@/services';
import { changeQuestionsOrder } from '@/slices';
import { getIds, getSortValuesFunc, isCrossword } from '@/utils';

import { AddQuestionButton } from '../../components/AddQuestionButton';
import styles from './styles.module.scss';

type Props = {
  selectedQuestion: number;
  setActiveEditContent: React.Dispatch<SetStateAction<string | number>>;
  test: TestResponsePrivate;
};

export const QuestionsPreviewList: React.FC<Props> = ({
  selectedQuestion,
  setActiveEditContent,
  test,
}) => {
  const { id, type } = test;

  const dispatch = useDispatch();

  const [updateQuestionsOrder] = useUpdateQuestionsOrderMutation();

  useGetQuestionsListQuery(
    { limit: 100, offset: 0, relations: ['media', 'answers'], test: id },
    {
      refetchOnMountOrArgChange: true,
      skip: !id,
    },
  );

  const questions = useSelector(questionsItemsSelector);

  const sensors = useSensors(
    useSensor(TouchSensor, {
      activationConstraint: { delay: 300, tolerance: 50 },
    }),
    useSensor(MouseSensor, {
      activationConstraint: { delay: 0, tolerance: 50 },
    }),
  );

  const onDragEnd = useCallback(
    (event: DragEndEvent) => {
      const dragSort = getSortValuesFunc(questions);
      const sortChanges = dragSort(event);
      dispatch(changeQuestionsOrder([...sortChanges]));
      updateQuestionsOrder({ id, ids: getIds(sortChanges) });
    },
    [dispatch, id, questions, updateQuestionsOrder],
  );

  if (!questions) {
    return <div>Нет вопросов!</div>;
  }

  return (
    <div className={styles.container}>
      <div className={styles.questionsPreviewList}>
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={onDragEnd}>
          <SortableContext
            items={questions}
            strategy={rectSortingStrategy}
            disabled={isCrossword(type)}
          >
            {questions.map((question, index) => (
              <SortableItem id={question.id} key={question.id} childHandle>
                <QuestionPreview
                  question={question}
                  index={index}
                  onClick={setActiveEditContent}
                  selected={question.id === selectedQuestion}
                  canDelete={questions.length > 1}
                />
              </SortableItem>
            ))}
          </SortableContext>
        </DndContext>
      </div>
      <AddQuestionButton test={test} />
    </div>
  );
};
