import { Player } from '@lottiefiles/react-lottie-player';
import { TestType } from '@tests/types';
import classNames from 'classnames';
import { always, cond, equals, T } from 'ramda';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Loader } from '@/assets';
import { LeftSideMenu, Visible } from '@/components';
import { EditMenu } from '@/constants';
import {
  GeneralSettings,
  IframeCodeBlock,
  Question,
  QuestionsList,
  QuestionsPreviewList,
  Results,
  TestEditPageHeader,
} from '@/containers';
import { useGetBackgroundsQuery, useGetTestByIdQuery } from '@/services';
import { setValidationErrors } from '@/slices';
import { isCrossword } from '@/utils';

import styles from './styles.module.scss';

type IMenuItem = { key: string; label: string };

const generalSettingsMenuItem = {
  key: EditMenu.generalSettings,
  label: 'Общие настройки',
};

const resultsMenuItem = {
  key: EditMenu.results,
  label: 'Результаты',
};

const questionsMenuItem = {
  key: EditMenu.questions,
  label: 'Вопросы',
};

const iframeCodeMenuItem = {
  key: EditMenu.iframeCode,
  label: 'Код iframe',
};

const getMenuItems = cond<[TestType], IMenuItem[]>([
  [equals(TestType.Poll), always([generalSettingsMenuItem, iframeCodeMenuItem])],
  [
    equals(TestType.Crossword),
    always([generalSettingsMenuItem, questionsMenuItem, resultsMenuItem, iframeCodeMenuItem]),
  ],
  [T, always([generalSettingsMenuItem, resultsMenuItem, iframeCodeMenuItem])],
]);

export const TestEditPage: React.FC = () => {
  const [isMenuShowed, setIsMenuShowed] = useState(false);
  const [activeEditContent, setActiveEditContent] = useState<string | number>(
    EditMenu.generalSettings,
  );

  useEffect(() => {
    const testNumber = window.location.href.split('/').pop();
    const activeTestMenu = JSON.parse(sessionStorage.getItem('activeEditContent'));
    if (activeTestMenu && activeTestMenu[testNumber]) {
      setActiveEditContent(activeTestMenu[testNumber]);
    }
  }, []);

  const { idOrAlias = '' } = useParams();

  const { data: test, error: errorTest } = useGetTestByIdQuery(
    {
      id: idOrAlias,
      relations: [
        'projects',
        'questions',
        'results',
        'backgroundImage',
        'crosswordBackgroundImage',
      ],
    },
    { pollingInterval: 15000, refetchOnMountOrArgChange: true, skip: !idOrAlias },
  );

  useGetBackgroundsQuery(
    { id: test?.id, order: '-id' },
    { refetchOnMountOrArgChange: true, skip: !test?.id },
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (test?.validationErrors) {
      dispatch(setValidationErrors(test.validationErrors));
    }
  }, [dispatch, test, test?.validationErrors]);

  if (!test || errorTest) {
    return (
      <div className={styles.loaderWrapper}>
        <Player autoplay loop src={Loader} className={styles.loader} />
      </div>
    );
  }

  const handleMenuShow = () => {
    setIsMenuShowed(!isMenuShowed);
  };

  return (
    <>
      <TestEditPageHeader test={test} onMenuClick={handleMenuShow} isMenuShowed={isMenuShowed} />
      <div className={styles.editPageContent}>
        <div className={classNames(styles.leftContent, isMenuShowed && styles.showed)}>
          <div className={styles.leftMenuWrapper}>
            <LeftSideMenu
              items={getMenuItems(test.type)}
              activeMenu={activeEditContent}
              setActiveEditContent={setActiveEditContent}
              questionsCount={test.questions?.length}
            />
            <Visible isVisible={!isCrossword(test.type)}>
              <QuestionsPreviewList
                test={test}
                setActiveEditContent={setActiveEditContent}
                selectedQuestion={activeEditContent as number}
              />
            </Visible>
          </div>
        </div>
        <div className={styles.rightContent}>
          <Visible isVisible={activeEditContent === EditMenu.generalSettings}>
            <GeneralSettings test={test} />
          </Visible>
          <Visible isVisible={activeEditContent === EditMenu.questions}>
            <QuestionsList test={test} />
          </Visible>
          <Visible isVisible={activeEditContent === EditMenu.results}>
            <Results test={test} />
          </Visible>
          <Visible isVisible={activeEditContent === EditMenu.iframeCode}>
            <IframeCodeBlock id={test.id} test={test} />
          </Visible>
          <Visible isVisible={typeof activeEditContent === 'number'}>
            {test.questions?.map((question) => (
              <Visible isVisible={activeEditContent === question.id}>
                <Question
                  questionId={activeEditContent as number}
                  key={question.id}
                  testId={test.id}
                  testType={test.type}
                />
              </Visible>
            ))}
          </Visible>
        </div>
      </div>
    </>
  );
};
