import { GetManyTestsQueryPrivate } from '@tests/types';
import { Button, Col, Form, Row } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { isEmpty, omit } from 'ramda';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { DateFilter, ProjectsFilter, SelectRender } from '@/components';
import { useGetTestsListQuery } from '@/services';

import { DEFAULT_FILTER_KEYS } from '../../constants';
import { useFilterPage } from '../../hooks';
import styles from './styles.module.scss';

type FiltersItem = {
  label: string;
  value: number;
};

type FiltersProps = {
  employeesList: FiltersItem[];
  hasMoreEmployees: boolean;
  loadingEmployees: boolean;
  onFetchEmployees: () => Promise<void>;
};

const convertProjects = (projects: string | string[]) => {
  if (projects) {
    if (Array.isArray(projects)) {
      return projects.map((id: string) => ({ value: +id }));
    }

    return [{ value: +projects }];
  }

  return [];
};

export const FiltersComponent: FC<FiltersProps> = ({
  employeesList,
  hasMoreEmployees,
  loadingEmployees,
  onFetchEmployees,
}) => {
  const RESET_FILTER_KEYS = ['offset', 'page', ...DEFAULT_FILTER_KEYS];
  const { filter, resetFilter, setFilter } = useFilterPage();
  const [checkedProjects, setCheckedProjects] = useState(
    convertProjects(filter?.projects) as FiltersItem[],
  );
  const [employee, setEmployee] = useState(filter?.createdBy ? +filter.createdBy : null);
  const [selectedPublishedDate, setSelectedPublishedDate] = useState(filter?.publishedAt || null);
  const [selectedCreatedDate, setSelectedCreatedDate] = useState(filter?.createdAt || null);
  const [status, setStatus] = useState(filter?.status || null);
  const [form] = Form.useForm();

  form.setFieldsValue({
    createdAt: selectedCreatedDate?.map((date: string) => dayjs(date)),
    publishedAt: selectedPublishedDate?.map((date: string) => dayjs(date)),
  });

  const getListQueryParams = useMemo(() => {
    const params = {} as Partial<GetManyTestsQueryPrivate<string>>;
    if (selectedPublishedDate) {
      params.publishedAt = selectedPublishedDate;
    }
    if (selectedCreatedDate) {
      params.createdAt = selectedCreatedDate;
    }
    if (checkedProjects.length) {
      params.projects = checkedProjects.map(({ value }: { value: number }) => value);
    }
    if (employee) {
      params.createdBy = employee;
    }
    if (status) {
      params.status = status;
    }

    return params;
  }, [selectedCreatedDate, checkedProjects, employee, selectedPublishedDate, status]);

  const handleStatusChange = (value: string) => {
    setStatus(value);
  };

  const { isFetching } = useGetTestsListQuery(
    {
      ...omit(['page'], filter),
    },
    {
      skip: true,
    },
  );

  const handleEmployeeChange = (value: string) => {
    if (value) {
      return setEmployee(+value);
    }

    return setEmployee(null);
  };

  const onFinish = useCallback(() => {
    resetFilter({
      ...omit(RESET_FILTER_KEYS, filter),
      ...getListQueryParams,
    });
  }, [getListQueryParams, setFilter]);

  const handleReset = useCallback(() => {
    setCheckedProjects([]);
    setEmployee(null);
    setSelectedCreatedDate(null);
    setSelectedPublishedDate(null);
    setStatus(null);
    form.resetFields();
    resetFilter(omit(RESET_FILTER_KEYS, filter));
  }, [form, resetFilter, filter]);

  useEffect(() => {
    if (isEmpty(getListQueryParams)) {
      resetFilter(omit(DEFAULT_FILTER_KEYS, filter));
    }
  }, [getListQueryParams, resetFilter]);

  const statusOptions = [
    { label: 'Черновик', value: 'draft' },
    { label: 'Опубликована', value: 'published' },
    { label: 'Снята с публикации', value: 'unpublished' },
  ];

  return (
    <Form form={form} onFinish={onFinish}>
      <Row className={styles.filters}>
        <Row className={styles.selectors} gutter={[20, 20]}>
          <Col className={styles.selectorWrapper}>
            <Row>
              <Col>
                <div className={styles.selectorLabel}>Проект</div>
                <ProjectsFilter
                  checkedProjects={checkedProjects}
                  setCheckedProjects={setCheckedProjects}
                />
              </Col>
            </Row>
          </Col>
          <Col className={styles.selectorWrapper}>
            <Row>
              <Col>
                <div className={styles.selectorLabel}>Сотрудник</div>
                <SelectRender
                  value={employee}
                  options={employeesList}
                  onChange={handleEmployeeChange}
                  hasMore={hasMoreEmployees}
                  fetchData={onFetchEmployees}
                  loading={loadingEmployees}
                />
              </Col>
            </Row>
          </Col>
          <Col className={styles.selectorWrapper}>
            <Row>
              <Col>
                <div className={styles.selectorLabel}>Статус</div>
                <SelectRender
                  value={status}
                  onChange={handleStatusChange}
                  options={statusOptions}
                />
              </Col>
            </Row>
          </Col>
          <Col className={styles.selectorWrapper}>
            <Row>
              <Col>
                <div className={styles.selectorLabel}>Период создания</div>
                <DateFilter
                  selectedDate={selectedCreatedDate}
                  setSelectedDate={setSelectedCreatedDate}
                  name="createdAt"
                />
              </Col>
            </Row>
          </Col>
          <Col className={styles.selectorWrapper}>
            <Row>
              <Col>
                <div className={styles.selectorLabel}>Период публикации</div>
                <DateFilter
                  selectedDate={selectedPublishedDate}
                  setSelectedDate={setSelectedPublishedDate}
                  name="publishedAt"
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[15, 0]} className={styles.filtersBtnWrapper} justify="end">
          <Col className={styles.filtersBtnCol}>
            <Button
              className={classNames(styles.filtersBtn, styles.resetFilters)}
              onClick={handleReset}
              disabled={isEmpty(getListQueryParams)}
            >
              Сбросить
            </Button>
          </Col>
          <Col className={styles.filtersBtnCol}>
            <Form.Item>
              <Button
                disabled={isFetching || isEmpty(getListQueryParams)}
                htmlType="submit"
                className={classNames(styles.filtersBtn, styles.filtersSubmitBtn)}
                type="primary"
              >
                Применить
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Row>
    </Form>
  );
};
