import { ExclamationCircleFilled } from '@ant-design/icons';
import { TestValidationError, TestValidationErrorTarget } from '@tests/types';
import { Col, Row } from 'antd';
import classNames from 'classnames';
import React, { SetStateAction, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { EditMenu } from '@/constants';
import { useDisabledByUpdate } from '@/hooks';
import { validationErrorsTestsSelector } from '@/selectors';
import { getValidationErrorsByTarget, getValidationErrorsByTargetAndField } from '@/utils';

import styles from './styles.module.scss';

type Props = {
  activeMenu: string | number;
  items: { key: string; label: string }[];
  questionsCount: number;
  setActiveEditContent: React.Dispatch<SetStateAction<string | number>>;
};

const getValidationErrorsMatched = (menuKey: string, validationErrors: TestValidationError[]) => {
  switch (menuKey) {
    case EditMenu.generalSettings: {
      return getValidationErrorsByTarget(validationErrors, TestValidationErrorTarget.Test);
    }
    case EditMenu.results: {
      return getValidationErrorsByTarget(validationErrors, TestValidationErrorTarget.Result);
    }
    case EditMenu.questions: {
      return [
        ...(getValidationErrorsByTarget(validationErrors, TestValidationErrorTarget.Question) ||
          []),
        ...(getValidationErrorsByTarget(validationErrors, TestValidationErrorTarget.Answer) || []),
      ];
    }
    case EditMenu.iframeCode: {
      return getValidationErrorsByTargetAndField(
        validationErrors,
        TestValidationErrorTarget.Test,
        'crossword',
      );
    }
    default: {
      return null;
    }
  }
};

export const LeftSideMenu: React.FC<Props> = ({
  items = [],
  setActiveEditContent,
  activeMenu,
  questionsCount,
}) => {
  const [selectedItem, setSelectedItem] = useState(activeMenu || null);

  const disabled = useDisabledByUpdate();

  const validationErrors = useSelector(validationErrorsTestsSelector);

  useEffect(() => {
    setSelectedItem(activeMenu);
    const testNumber = window.location.href.split('/').pop();
    const activeTestMenu: Record<string, string> = {};
    activeTestMenu[testNumber] = String(activeMenu);
    sessionStorage.setItem('activeEditContent', JSON.stringify(activeTestMenu));
  }, [activeMenu]);

  if (!items) {
    return <div>Ошибка загрузки пунктов меню!</div>;
  }

  return (
    <Row>
      {items.map(({ key, label }) => {
        const hasErrors = getValidationErrorsMatched(key, validationErrors)?.length > 0;

        return (
          <Col
            onClick={() => {
              setSelectedItem(key);
              setActiveEditContent(key);
            }}
            span={24}
            className={classNames(
              styles.item,
              selectedItem === key && styles.selected,
              disabled && styles.disabled,
              hasErrors && styles.hasErrors,
            )}
          >
            <div>
              {label}
              {key === 'questions' && (
                <span className={styles.questionsCount}>{questionsCount}</span>
              )}
            </div>
            {hasErrors && <ExclamationCircleFilled className={styles.error} />}
          </Col>
        );
      })}
    </Row>
  );
};
