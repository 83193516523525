import { message, Modal } from 'antd';
import classNames from 'classnames';
import React, { FC } from 'react';

import { Trash } from '@/assets';

import { useDeleteBackgroundMutation } from '../../services';
import styles from './styles.module.scss';

const { confirm } = Modal;

interface Props {
  alt: string;
  id: number;
  large?: boolean;
  testId: number;
  url: string;
}

export const InteractiveImage: FC<Props> = ({ alt, id, large, testId, url }) => {
  const [deleteBackground] = useDeleteBackgroundMutation();

  const handleDelete = async () => {
    confirm({
      cancelText: 'Отменить',
      okText: 'Удалить',
      onOk: async () => {
        await deleteBackground({ id, testId });
        message.success('Изображение удалено');
      },
      title: 'Вы действительно хотите удалить изображение?',
    });
  };

  return (
    <div className={classNames(styles.container, large && styles.large)}>
      <img src={url} alt={alt} className={styles.image} />
      <div className={styles.deleteIcon} onClick={handleDelete}>
        <Trash className={styles.icon} />
      </div>
    </div>
  );
};
